import React from "react";
import * as styles from "./career.module.scss";
import PageLayout from "../components/layout/PageLayout";
import Masthead from "../components/layout/Masthead";
import Seo from "../components/layout/SEO";
import Intro from "../components/layout/Intro";
import OpenPosition from "../components/career/OpenPosition";
import ScrollReveal from "../components/layout/ScrollReveal";

import careerAnim from "../assets/json/career.json";

const career = () => {
  return (
    <PageLayout anim={careerAnim}>
      <Seo title="Career - Simplicitic Innovations Sdn Bhd" />
      <Masthead title="Join the team" tag="Join the team" />
      <Intro>
        <ScrollReveal effect="slide-up" delay={50}>
          <p className={`h4 text-center ${styles["intro"]}`}>
            If you like to deliver 'simple' solutions for complex business
            challenges, we welcome you to join us. We are looking for designers,
            developers, strategists, and creators. Get to know us and join the
            SIMPLE Team!
          </p>
        </ScrollReveal>
      </Intro>
      <OpenPosition />
    </PageLayout>
  );
};

export default career;
