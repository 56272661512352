// extracted by mini-css-extract-plugin
export var active = "ModalPosition-module--active--C5S5q";
export var background = "ModalPosition-module--background--XoIao";
export var btn_back = "ModalPosition-module--btn_back--bJa7a";
export var btn_close = "ModalPosition-module--btn_close--zicae";
export var container = "ModalPosition-module--container--0e2yS";
export var form = "ModalPosition-module--form--xw04D";
export var form_open = "ModalPosition-module--form_open--SNMEs";
export var hide = "ModalPosition-module--hide--kLRaw";
export var modal = "ModalPosition-module--modal--uMsrq";
export var modal_opened = "ModalPosition-module--modal_opened--MYfbL";
export var positionInfo = "ModalPosition-module--position-info--IOCXj";