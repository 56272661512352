import React, { useRef, useState, useEffect } from "react";
import * as styles from "./CareerForm.module.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import qs from "qs";
import axios from "axios";
import * as Yup from "yup";
import classNames from "classnames";
import {
  loadRecaptcha,
  removeRecaptcha,
  getRecaptchaToken,
} from "../../utils/googleRecaptcha";

import upload from "../../images/upload.svg";

const validation = Yup.object().shape({
  candidateName: Yup.string().required("Please enter your name"),
  candidateEmail: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter your email"),
  candidateMessage: Yup.string().required("Please enter your message"),
  candidateFile: Yup.string().required("Your resume is requested"),
});

const InputField = ({ id, inputName, placeholder, type, component }) => {
  const [inFocus, setInFocus] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setInFocus(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [ref]);
  return (
    <div
      ref={ref}
      onClick={() => setInFocus(true)}
      onKeyDown={() => setInFocus(true)}
      className={classNames(styles.inputField, inFocus ? styles.focus : null)}
      role="presentation"
    >
      <Field
        component={component ? component : "input"}
        id={id}
        name={inputName}
        placeholder={placeholder}
        type={type ? type : "text"}
        className={styles["input"]}
        autoComplete="false"
      />
    </div>
  );
};

const CareerForm = ({ position }) => {
  const [loading, setLoading] = useState(false);
  const [fieldValue, hasFieldValue] = useState(false);

  const initialValues = {
    candidateName: "",
    candidateEmail: "",
    candidatePosition: position,
    candidateMessage: "",
    candidateFile: "",
  };

  useEffect(() => {
    loadRecaptcha();
    return () => removeRecaptcha();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      validateOnChange={false}
      onSubmit={async (values, { resetForm, setStatus, setSubmitting }) => {
        setLoading(true);
        // console.log(values);
        console.log({
          fileName: values.file.name,
          type: values.file.type,
          size: `${values.file.size} bytes`,
        });
        try {
          const recaptchaCode = await getRecaptchaToken("career_form");
          const response = await axios({
            method: "post",
            url: "https://dev.simplicitic.com/simplicitic-mail/CareerFormMailing.php",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: qs.stringify({ ...values, token: recaptchaCode }),
            withCredentials: false,
          });
          console.log(response);
          if (response.data.success) {
            resetForm();
            setStatus({
              success: true,
              message: "Message has been sent!",
            });
            setSubmitting(false);
          } else {
            setStatus({
              success: false,
              message: "Error sending form. Please try again later.",
            });
          }
        } catch (error) {
          setStatus({
            success: false,
            message: `${error}. Please try again later.`,
          });
        }
        setLoading(false);
      }}
    >
      {({ status, handleChange, setFieldValue }) => (
        <Form>
          <div>
            <label htmlFor="candidateName" className={styles["label"]}>
              Name
            </label>
            <InputField
              id="candidateName"
              inputName="candidateName"
              placeholder="John Smith"
            />
            <ErrorMessage
              className="msg-error"
              component="div"
              name="candidateName"
            />
          </div>
          <div>
            <label htmlFor="candidateEmail" className={styles["label"]}>
              Email
            </label>
            <InputField
              id="candidateEmail"
              inputName="candidateEmail"
              placeholder="johnsmith@example.com"
              type="email"
            />
            <ErrorMessage
              className="msg-error"
              component="div"
              name="candidateEmail"
            />
          </div>
          <div>
            <label htmlFor="candidatePosition" className={styles["label"]}>
              Position
            </label>
            <InputField id="candidatePosition" inputName="candidatePosition" />
          </div>
          <div>
            <label htmlFor="candidateMessage" className={styles["label"]}>
              Message
            </label>

            <InputField
              component="textarea"
              id="candidateMessage"
              inputName="candidateMessage"
              placeholder="Your message here..."
            />
            <ErrorMessage
              className="msg-error"
              component="div"
              name="candidateMessage"
            />
          </div>
          <div>
            <Field
              component="input"
              id="candidateFile"
              name="candidateFile"
              type="file"
              className={styles["input"]}
              autoComplete="false"
              onInput={handleChange}
              onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
                hasFieldValue(true);
              }}
            ></Field>
            <label
              htmlFor="candidateFile"
              className={styles[("label", "customLabel")]}
            >
              <img src={upload} alt="" />
              {fieldValue ? "Change file" : "Upload your resume"}
            </label>
            <ErrorMessage
              className="msg-error"
              component="div"
              name="candidateFile"
            />
          </div>
          {status && status.message && (
            <div
              className={`alert ${
                status.success ? "alert-success" : "alert-error"
              }`}
            >
              {status.message}
            </div>
          )}
          <button type="submit" className="btn" disabled={loading}>
            Submit
          </button>
          {loading ? <div className="loading"></div> : ""}
        </Form>
      )}
    </Formik>
  );
};

export default CareerForm;
