import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import * as styles from "./ModalPosition.module.scss";

import CareerForm from "./CareerForm";

const ModalPosition = ({ data, onClose }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [formOpened, setFormOpened] = useState(false);

  useEffect(() => {
    data && setModalOpen(true);
  }, [data]);

  return (
    <>
      <Modal
        closeTimeoutMS={1250}
        isOpen={modalOpen}
        onAfterOpen={() => setModalOpened(true)}
        onAfterClose={() => onClose()}
        className={classNames(
          styles["modal"],
          modalOpened && styles["modal_opened"],
          formOpen && styles["form_open"]
        )}
        clas
        style={{ overlay: { background: "transparent" } }}
      >
        <div className={styles["background"]}></div>
        {formOpen ? (
          <button
            onClick={() => {
              setFormOpened(false);
              setTimeout(() => {
                setFormOpen(false);
              }, 300);
            }}
            className={styles.btn_back}
            aria-label="back button"
          ></button>
        ) : null}
        <button
          onClick={() => {
            setModalOpened(false);
            setModalOpen(false);
            setTimeout(() => {
              setFormOpened(false);
              setFormOpen(false);
            }, 300);
          }}
          className={styles["btn_close"]}
        >
          <span></span>
        </button>
        <div className={classNames("wrapper", styles.container)}>
          <div
            className={classNames(
              styles.positionInfo,
              formOpen ? styles.hide : null
            )}
          >
            <h2 className="h2 test">{data.name}</h2>
            {data.responsibilities && (
              <>
                <h3 className="h4">Responsibilities</h3>
                <ul className="custom-list">
                  {data.responsibilities.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </>
            )}
            {data.requirements && (
              <>
                <h3 className="h4">Requirements</h3>
                <ul className="custom-list">
                  {data.requirements.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </>
            )}
            <button
              onClick={() => {
                setFormOpen(true);
                setTimeout(() => {
                  setFormOpened(true);
                }, 500);
              }}
              className="btn"
            >
              Apply Now
            </button>
          </div>
          {formOpen ? (
            <div
              className={classNames(
                styles.form,
                formOpened ? styles.active : null
              )}
            >
              <div>
                <CareerForm position={data.name} />
              </div>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default ModalPosition;
